import './App.css';
import './index.css';
import Example from './Components/Example';
import AboutUs from './Components/AboutUs';
import Services from './Components/Services';
import Contact from './Components/Contact';

function App() {
  return (
    <div class="w-full">
      <Example/>
      <AboutUs/>
      <Services/>
      <Contact/>
    </div>
  );
}

export default App;
