'use client'

import React from 'react';
import { useState } from 'react'
import Carousel from './Carousel';

export default function Services() {
    const [selectedValue, setSelectedValue] = useState(0);

    const handleRadioChange = (event) => {
        setSelectedValue(parseInt(event.target.value, 10));
    };

    return (
        <div id="services" class="w-full bg-white">
            <div class="container mx-auto grid grid-cols-1 md:grid-cols-12 md:gap-10">
                <p class="text-lg lg:px-20 px-10 col-span-full text-gray-900 mt-10 text-center">
                    Servicing the Lake Country Area including Oconomowoc, Hartland, Delafield,
                     Dousman, Sussex, Lannon, Watertown, Hartford, Stone Bank, Brookfield, Menomonee Falls, Waukesha, and more.
                </p>
                <fieldset class="space-y-4 flex flex-col items-center justify-center col-span-full md:col-span-6">
                    <legend class="sr-only">Services</legend>
                    <div class="w-full flex flex-col px-4 md:hidden md:collapse">
                        <label for="dropdown" class="font-bold">Services</label>
                        <select name="dropdown" onChange={handleRadioChange} id="dropdown" class="w-full p-4 rounded-md border border-gray-300">
                            <option value={0}>Select a Service</option>
                            <option value={1}>Stone Veneers (full & thin)</option>
                            <option value={2}>Brick Veneer & Brick Pavers</option>
                            <option value={3}>Fireplace Fronts</option>
                            <option value={4}>Fireplace Full Masonry</option>
                            <option value={5}>Block Foundations</option>
                            <option value={6}>Retaining Walls</option>
                            <option value={7}>Skid Loader Work & Grading</option>
                            <option value={8}>Concrete Flat Work</option>
                        </select>
                    </div>
                    <div class="w-full items-center justify-center invisible h-0 md:h-min md:visible">
                        <label
                            for="stone-veneers"
                            class="flex cursor-pointer max-w-[90vw] items-center justify-between gap-4 rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-xs hover:border-gray-200 has-[:checked]:border-blue-500 has-[:checked]:ring-1 has-[:checked]:ring-blue-500"
                        >
                            <p class="text-gray-700">Stone Veneers (full & thin)</p>
                            <input
                                type="radio"
                                name="Services"
                                value={1}
                                id="stone-veneers"
                                class="sr-only"
                                onChange={handleRadioChange}
                            />
                        </label>
                    </div>

                    <div class="w-full items-center justify-center invisible h-0 md:h-min md:visible"> 
                        <label
                            for="brick-veneers"
                            class="flex cursor-pointer max-w-[90vw] items-center justify-between gap-4 rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-xs hover:border-gray-200 has-[:checked]:border-blue-500 has-[:checked]:ring-1 has-[:checked]:ring-blue-500"
                        >
                            <p class="text-gray-700">Brick Veneer & Brick Pavers</p>
                            <input
                                type="radio"
                                name="Services"
                                value={2}
                                id="brick-veneers"
                                class="sr-only"
                                onChange={handleRadioChange}
                            />
                        </label>
                    </div>

                    <div class="w-full items-center justify-center invisible h-0 md:h-min md:visible">
                        <label
                            for="fireplace-fronts"
                            class="flex cursor-pointer max-w-[90vw] items-center justify-between gap-4 rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-xs hover:border-gray-200 has-[:checked]:border-blue-500 has-[:checked]:ring-1 has-[:checked]:ring-blue-500"
                        >
                            <p class="text-gray-700">Fireplace Fronts</p>
                            <input
                                type="radio"
                                name="Services"
                                value={3}
                                id="fireplace-fronts"
                                class="sr-only"
                                onChange={handleRadioChange}
                            />
                        </label>
                    </div>

                    <div class="w-full items-center justify-center invisible h-0 md:h-min md:visible"> 
                        <label
                            for="fireplace-full"
                            class="flex cursor-pointer max-w-[90vw] items-center justify-between gap-4 rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-xs hover:border-gray-200 has-[:checked]:border-blue-500 has-[:checked]:ring-1 has-[:checked]:ring-blue-500"
                        >
                            <p class="text-gray-700">Fireplace Full Masonry</p>
                            <input
                                type="radio"
                                name="Services"
                                value={4}
                                id="fireplace-full"
                                class="sr-only"
                                onChange={handleRadioChange}
                            />
                        </label>
                    </div>

                    <div class="w-full items-center justify-center invisible h-0 md:h-min md:visible">
                        <label
                            for="block-foundations"
                            class="flex cursor-pointer max-w-[90vw] items-center justify-between gap-4 rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-xs hover:border-gray-200 has-[:checked]:border-blue-500 has-[:checked]:ring-1 has-[:checked]:ring-blue-500"
                        >
                            <p class="text-gray-700">Block Foundations</p>
                            <input
                                type="radio"
                                name="Services"
                                value={5}
                                id="block-foundations"
                                class="sr-only"
                                onChange={handleRadioChange}
                            />
                        </label>
                    </div>

                    <div class="w-full items-center justify-center invisible h-0 md:h-min md:visible">
                        <label
                            for="retaining-walls"
                            class="flex cursor-pointer max-w-[90vw] items-center justify-between gap-4 rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-xs hover:border-gray-200 has-[:checked]:border-blue-500 has-[:checked]:ring-1 has-[:checked]:ring-blue-500"
                        >
                            <p class="text-gray-700">Retaining Walls</p>
                            <input
                                type="radio"
                                name="Services"
                                value={6}
                                id="retaining-walls"
                                class="sr-only"
                                onChange={handleRadioChange}
                            />
                        </label>
                    </div>

                    <div class="w-full items-center justify-center invisible h-0 md:h-min md:visible">
                        <label
                            for="skid-loader"
                            class="flex cursor-pointer max-w-[90vw] items-center justify-between gap-4 rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-xs hover:border-gray-200 has-[:checked]:border-blue-500 has-[:checked]:ring-1 has-[:checked]:ring-blue-500"
                        >
                            <p class="text-gray-700">Skid Loader Work & Grading</p>
                            <input
                                type="radio"
                                name="Services"
                                value={7}
                                id="skid-loader"
                                class="sr-only"
                                onChange={handleRadioChange}
                            />
                        </label>
                    </div>

                    <div class="w-full items-center justify-center invisible h-0 md:h-min md:visible">
                        <label
                            for="concrete-flat"
                            class="flex flex-col cursor-pointer max-w-[90vw] items-start gap-4 rounded-lg border border-gray-100 bg-white p-4 text-sm font-medium shadow-xs hover:border-gray-200 has-[:checked]:border-blue-500 has-[:checked]:ring-1 has-[:checked]:ring-blue-500"
                        >
                            <p class="text-gray-700">Concrete Flat Work</p>
                            <p class="text-gray-700 text-xs">(Floors, Slabs, Sidewalks, Driveways, Exposed Aggregate)</p>
                            <input
                                type="radio"
                                name="Services"
                                value={8}
                                id="concrete-flat"
                                class="sr-only"
                                onChange={handleRadioChange}
                            />
                        </label>
                    </div>
                </fieldset>
                <div class="col-span-full max-h-[80vh] md:col-span-6 w-full flex items-center justify-center">
                    <Carousel id={selectedValue} />
                </div>
            </div>
        </div>

    )
}