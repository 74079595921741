'use client'

import React from 'react';
import magazine from '../Images/MagazineCover.jpg';

export default function AboutUs() {
  return (
    <div id="about" class="w-full bg-gray-100">
        <div class="container mx-auto p-8">
            <h1 class="text-3xl font-bold text-gray-900">About Us</h1>
            <p class="text-2xl text-gray-700 mt-4">Our local, well-established, fully insured business has been family owned and operated for over 35 years. We offer you a combined experience level of 100 plus years.</p>
            <div class="w-full grid grid-cols-12 mt-10">
                <div class="col-span-full md:col-span-8 flex flex-col items-center justify-center md:p-8">
                    <h3 class="text-xl font-bold text-gray-900">Superior Craftsmanship Is Our Legacy</h3>
                    <p class="text-lg text-gray-900">Our builders have experience in a variety of home styles. We were featured in the 2018 edition of Milwaukee's Lifestyle Magazine. We provide new and remodeling residential masonry to all Southeastern Wisconsin.</p>
                </div>
                <div class="col-span-full md:col-span-4">
                    <img src={magazine}/>
                </div>
                <div class="col-span-full md:col-span-6 mt-10">

                </div>
            </div>
        </div>
    </div>
  )
}