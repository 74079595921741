'use client'

import React from 'react';
import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../Images/RealLogo.png';
import blacklogo from '../Images/BlackLogo.png';
import smalllogo from '../Images/BlackLogoSmall.png';
import headerPhoto from '../Images/HeaderPhoto.jpg';

const navigation = [
  { name: 'Home', href: '#home' },
  { name: 'About Us', href: '#about' },
  { name: 'Services', href: '#services' },
  { name: 'Contact', href: '#contact' },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white w-full">
      <header className="absolute sticky inset-x-0 top-0 z-50 bg-white shadow-sm">
        <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a href="#home" className="-m-1.5 p-1.5 flex flex-row gap-2 items-center">
              <img
                alt=""
                src={smalllogo}
                className="h-8 w-auto"
              />
              <h3 className="text-2xl font-bold text-left w-full">Grimm Masonry</h3>
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm/6 font-semibold text-gray-900" onClick={() => setMobileMenuOpen(false)}>
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#home" className="-m-1.5 p-1.5">
                <span className="sr-only">Grimm Masonry</span>
                <img
                  alt=""
                  src={smalllogo}
                  className="h-8 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
      <div style={{ backgroundImage: `url(${headerPhoto})`}} class="grid grid-cols-12 mx-auto w-[100vw] h-[100vh] bg-cover bg-center">
          <div class="col-span-full md:col-span-5 flex items-center justify-end">
              <img className={"fade-in"} src={logo} alt="company logo"/>
          </div>
          <div class="col-span-full md:col-span-7 flex flex-col sm:items-start items-center justify-center fade-in lg:pt-80">
              <h2 class="text-3xl text-white font-bold">Family Owned Since 1986</h2>
              <h2 class="text-3xl text-white font-bold">Oconomowoc, WI</h2>
          </div>
      </div>
    </div>
  )
}