'use client'

import React from 'react';
import StoneVeneer from '../Images/StoneVeneer.jpg';
import StoneVeneer2 from '../Images/StoneVeneer2.jpg';
import StoneVeneer3 from '../Images/StoneVeneer3.jpg';
import BrickPaving from '../Images/BrickPaving.jpg';
import BrickVeneer from '../Images/BrickVeneer.jpg';
import BrickVeneer2 from '../Images/BrickVeneer2.jpg';
import FullFireplace2 from '../Images/FullFireplace2.jpg';
import FireplaceFullMasonry from '../Images/FireplaceFullMasonry.jpg';
import FullFireplace from '../Images/FullFirePlace.jpg';
import NoPhoto from '../Images/NoPhoto.png';
import ConcreteFill from '../Images/ConcreteFill.jpg';
import StoneVeneers from '../Images/StoneVeneers.jpg';
import FireplaceFront from '../Images/FireplaceFront.jpg';

function Carousel({ id }) {

    const data = [
        {
            id: 1,
            image1: { StoneVeneer },
            image2: "./Images/StoneVeneer2.jpg",
            image3: "./Images/StoneVeneer3.jpg",
            text: "Stone Veneers (Full & Thin)"
        },
        {
            id: 2,
            image1: "./Images/BrickPaving.jpg",
            image2: "./Images/BrickVeneer.jpg",
            image3: "./Images/BrickVeneer2.jpg",
            text: "Brick Veneer & Brick Pavers"
        },
        {
            id: 3,
            image1: "./Images/FullFireplace2.jpg",
            image2: "./Images/FireplaceFullMasonry.jpg",
            image3: "./Images/FullFirePlace",
            text: "Fireplace Fronts & Hearths"
        },
        {
            id: 4,
            image1: "./Images/NoPhoto.png",
            image2: "./Images/NoPhoto.png",
            image3: "./Images/NoPhoto.png",
            text: "Chimney Repairs & Rebuilds"
        },
        {
            id: 5,
            image1: "./Images/StoneVeneer2.jpg",
            image2: "./Images/StoneVeneer.jpg",
            image3: "./Images/NoPhoto.png",
            text: "Brick & Stone Steps"
        },
        {
            id: 6,
            image1: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            image2: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            image3: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            text: "Brick & Stone Patios"
        },
        {
            id: 7,
            image1: "./Images/BrickPaving.jpg",
            image2: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            image3: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            text: "Brick & Stone Walkways"
        },
        {
            id: 8,
            image1: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            image2: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            image3: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            text: "Brick & Stone Retaining Walls"
        },
        {
            id: 9,
            image1: "./Images/ConcreteFill.jpg",
            image2: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            image3: "https://happy-knuth-eef8ae.netlify.app/static/media/StoneVeneers.bbbfdef7d9a1a1991ce4.jpg",
            text: "Brick & Stone Retaining Walls"
        }
    ]
    return (
        <div class="w-full flex items-center justify-center p-4">
            <div class={id === 1 ? "grid grid-cols-1 grid-cols-2 items-center justify-center w-full h-auto mt-10 gap-4 rounded-lg fade-in" : "fade-out collapse hidden"}>
                <div class="col-span-1 row-span-1 h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={StoneVeneer} alt="carousel" />
                </div>
                <div class="col-span-1 row-span-2 h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={StoneVeneer3} alt="carousel" />
                </div>
                <div class="col-span-1 row-span-1 h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={StoneVeneer2} alt="carousel" />
                </div>
            </div>
            <div class={id === 2 ? "grid grid-cols-1 grid-cols-2 items-center justify-center w-full h-auto mt-10 gap-4 rounded-lg fade-in" : "fade-out collapse hidden"}>
                <div class="col-span-1 h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={BrickPaving} alt="carousel" />
                </div>
                <div class="col-span-1 h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={BrickVeneer} alt="carousel" />
                </div>
                <div class="col-span-1 h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={BrickVeneer2} alt="carousel" />
                </div>
            </div>
            <div class={id === 3 ? "grid grid-cols-1 grid-cols-2 items-center justify-center w-full h-auto mt-10 gap-4 rounded-lg fade-in" : "fade-out collapse hidden"}>
                <div class="col-span-full h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={FireplaceFront} alt="carousel" />
                </div>
            </div>
            <div class={id === 4 ? "grid grid-cols-1 grid-cols-2 items-center justify-center w-full h-auto mt-10 gap-4 rounded-lg fade-in" : "fade-out collapse hidden"}>
                <div class="col-span-1 row-span-2 h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={FullFireplace2} alt="carousel" />
                </div>
                <div class="col-span-1 row-span-1 h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={FireplaceFullMasonry} alt="carousel" />
                </div>
                <div class="col-span-1 row-span-1 h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={FullFireplace} alt="carousel" />
                </div>
            </div>
            <div class={id === 5 ? "grid grid-cols-1 grid-cols-2 items-center justify-center w-full h-auto mt-10 gap-4 rounded-lg fade-in" : "fade-out collapse hidden"}>
                <div class="col-span-full h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={NoPhoto} alt="carousel" />
                </div>
            </div>
            <div class={id === 6 ? "grid grid-cols-1 grid-cols-2 items-center justify-center w-full h-auto mt-10 gap-4 rounded-lg fade-in" : "fade-out collapse hidden"}>
                <div class="col-span-full h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={NoPhoto} alt="carousel" />
                </div>
            </div>
            <div class={id === 7 ? "grid grid-cols-1 grid-cols-2 items-center justify-center w-full h-auto mt-10 gap-4 rounded-lg fade-in" : "fade-out collapse hidden"}>
                <div class="col-span-full h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={NoPhoto} alt="carousel" />
                </div>
            </div>
            <div class={id === 8 ? "grid grid-cols-1 grid-cols-2 items-center justify-center w-full h-auto mt-10 gap-4 rounded-lg fade-in" : "fade-out collapse hidden"}>
                <div class="col-span-full h-auto hover:scale-105 flex-shrink-0 rounded-lg transitions-all ease-in-out duration-1000 shadow-md">
                    <img class="object-cover" src={ConcreteFill} alt="carousel" />
                </div>
            </div>
        </div>

    )
}
export default Carousel;