'use client'

import { React, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';

export default function Contact() {
    const [showAlert, setShowAlert] = useState(false);
    const form = useRef();

    function sendEmail(formData) {
        emailjs.sendForm('service_24y0r3m', 'template_7tn210w', form.current, { publicKey: '17NE5l31UL8OA0DA9'})
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        handleShowAlert();
    }

    const handleShowAlert = () => {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000); // Hide after 5 seconds
    };

    return (
        <div id="contact" class="w-full p-4 md:p-24 bg-gray-100 mt-10">
            <div class="w-full bg-gray-100 grid grid-cols-1 md:grid-cols-2 gap-4">
                <h2 class="col-span-1 text-2xl font-bold text-center">Contact Us</h2>
                <div class="col-span-1 flex flex-row md:items-end md:justify-end items-center justify-center">
                    <a href="tel:262-490-7517" class="col-span-1 align-end items-end h-10 w-32 md:w-64 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold transition-all delay-100 duration-200 ease-in-out hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                        <FontAwesomeIcon icon={faPhone} /> Call Now <span class="invisible md:visible">(262)-490-7517</span>
                    </a>
                </div>
                <form action={sendEmail} ref={form} class="col-span-full w-full bg-gray-100 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="flex flex-col col-span-1">
                        <label class="text-lg" for="name">Name</label>
                        <input type="text" required placeHolder="Name" name="name" class="p-4 rounded-md" />
                    </div>
                    <div class="flex flex-col cols-span-1">
                        <label class="text-lg" for="email">Email</label>
                        <input type="text" required placeHolder="return@email.com" name="email" class="p-4 rounded-md" />
                    </div>
                    <div class="flex flex-col col-span-full">
                        <label class="text-lg" required for="message">Message</label>
                        <textarea placeHolder="I would like an estimate for ...." name="message" class="p-4 rounded-md" />
                    </div>
                    <div class="flex flex-row gap-10 justify-arround col-span-full">
                        <button type="submit" class="transition-all delay-100 duration-200 ease-in-out bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                            Send
                        </button>
                    </div>
                </form>
            </div>
            {showAlert && (
                <div id="alert" class="fixed right-0 bottom-10 elementToFadeInAndOut bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
                    <p class="font-bold">Message Sent</p>
                    <p class="text-sm">We will get back to you as soon as we can.</p>
                </div>
            )}
        </div>
    )
}